import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import { withPrefix, Link } from 'gatsby';
import Img from "gatsby-image";
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const Home = (props) => {

  //Metadata
  const $metadata = props.data.prismic.allMetadatas.edges[0].node;

  //Footer
  const $footer = props.data.prismic.allFooters.edges[0].node;
  const $layoutData = { $metadata, $footer };

  //Articles
  let $articlesList = props.data.prismic.allBlogs.edges;


  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);


  return (

    <>

      <Layout data={$layoutData} footer={true} className={'u-home'} >


        <SEO metadata={$metadata}></SEO>

        <>

          <Helmet>

            <script src="../../assets/plugins/waypoints/noframework.waypoints.min.js" />

            <link href={withPrefix('../../assets/css/fixed/screen.css?v=2')} rel="stylesheet" type="text/css" />
            <link href={withPrefix('../../assets/css/sitio/article.css')} rel="stylesheet" type="text/css" />
            <link href={withPrefix('../../assets/css/sitio/home.css?v=2')} rel="stylesheet" type="text/css" />



          </Helmet>



          {/* HERO */}
          <div className="c-home-hero">
            <div className="c-home-hero__holder">
              <div className="c-home-hero__wrapper o-wrapper">{/*
                    */}<div className="c-home-hero__info">
                  <h2 className="c-home-hero__title c-title">¡Tenemos un <strong className="c-home-hero__title--strong">préstamo ideal</strong> para vos!</h2>
                  <ul className="c-home-hero__list">
                    <li className="c-home-hero__item c-home-hero__item-text">Completá el formulario.</li>
                    <li className="c-home-hero__item c-home-hero__item-text">Evaluamos tu perfil crediticio.</li>
                    <li className="c-home-hero__item c-home-hero__item-text">Te ofrecemos el <strong>préstamo que más se adapta a ti</strong>.</li>
                  </ul>
                  <Link to={`/solicitar-prestamo`} className="c-btn c-btn-desktop c-btn--filled">Buscar préstamo</Link>
                </div>{/*
                    */}

                <div className="c-home-hero__image">
                  <StaticImage
                    backgroundColor="transparent"
                    layout="fullWidth"
                    placeholder="blurred"
                    objectFit="contain"
                    alt="Tenemos un préstamo para vos"
                    src={
                      "../../static/assets/images/hero-image@2.png"
                    }
                    formats={["webp", "png"]}
                  />

                </div>


              </div>{/* .c-home-hero__wrapper */}
            </div>{/* .c-home-hero__holder */}
          </div>{/* .c-home-hero */}
          {/* ABOUT */}
          <div className="c-home-about o-section o-wrapper">
            <h2 className="u-hidden-for-seo">Sobre el sitio</h2>

            <p className="c-home__title">El sitio donde vas a poder evaluar las <strong className="c-home-about__title--strong">mejores ofertas</strong> del mercado y aprender <strong className="c-home-about__title--strong">finanzas</strong></p>

            <Link to={`/articulos`} className="c-btn c-btn-desktop c-btn--filled-orange">Visitar Blog</Link>

          </div>{/* .c-home-about */}




          {/* SERVICE */}
          <div className="c-home-service o-section">
            <div className="c-home-service__holder o-wrapper">
              <h2 className="c-home__title c-home__title--white">¿Cómo funciona?</h2>
              <ul className="c-home-service__list">{/*
                    */}<li className="c-home-service__item">
                  <span className="c-home-service__icon c-home-service__icon--fast" />
                  <span className="c-home-service__item-text"><strong className="c-home-service__title">Rápido</strong>Completás tus datos y evaluamos tu perfil crediticio.</span>
                </li>{/*
                    */}<li className="c-home-service__item">
                  <span className="c-home-service__icon c-home-service__icon--simple" />
                  <span className="c-home-service__item-text"><strong className="c-home-service__title">Fácil</strong>Te presentamos la oferta que mejor se adapta a ti!</span>
                </li>{/*
                    */}<li className="c-home-service__item">
                  <span className="c-home-service__icon c-home-service__icon--secure" />
                  <span className="c-home-service__item-text"><strong className="c-home-service__title">Seguro</strong>La empresa se pondrá en contacto para terminar la solicitud.</span>
                </li>{/*
                */}</ul>
            </div>{/* .o-wrapper */}
          </div>{/* .c-home-service */}



          {/* ARTICLES */}

          {$articlesList.length > 0 && hasMounted &&

            <Helmet>

              <script src="/assets/plugins/tiny-slider/tiny-slider.js"></script>
              <script src="/assets/js/sitio/home.js"></script>

            </Helmet>

          }

          {$articlesList.length > 0 && hasMounted &&
            <section className="c-carousel o-wrapper o-section js-articles-carousel js-carousel__notInit">
              <h2 className="u-hidden-for-seo">Artículos</h2>
              <div className="c-carousel__viewport">
                <ul className="js-carousel">



                  {$articlesList.map((field, i) => (

                    <li key={i} className={`c-article ${i != 0 ? "c-article--notInitHide" : ""}`}>
                      <article className="c-article__content">

                        {field.node.imagen && <Link to={`/articulos/${field.node._meta.uid}`} className="c-article__image-link" tabIndex="-1" title={field.node.titulo[0].text}>

                          <Img fluid={field.node.imagenSharp.childImageSharp.fluid} />

                        </Link>}


                        <div className="c-article__info">
                          <h2 className="c-article__title"><Link to={`articulos/${field.node._meta.uid}`}>{field.node.titulo ? field.node.titulo[0].text : ""}</Link></h2>
                          <p className="c-article__description">{field.node.descripcion ? field.node.descripcion[0].text : ""}</p>
                        </div>
                      </article>
                    </li>

                  ))}

                </ul>

                
              </div>

              <Link to={`/articulos`} className="c-btn c-btn-desktop c-btn--filled-orange c-carousel__btn">Ver todos</Link>


            </section>
          }
        </>


      </Layout>


    </>
  );
}



export const query = graphql`
query HomeQuery {
  prismic {
    allMetadatas  {
      edges {
        node {
          ...MetadataFragment
        }
      }
    },
    allFooters {
      edges {
        node {
          ...FooterFragment
        }
      }
    },
    allBlogs (first: 3,sortBy: fecha_DESC) {
      edges {
        node {
          _meta {
            uid
          }
          descripcion
          imagen
          imagenSharp {
            childImageSharp {
              fluid( quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          titulo
        }
      }
    }
  }
}
`

export default Home;